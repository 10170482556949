import {useAccountSecurityStore} from '~/stores/accountSecurity';

export default defineNuxtRouteMiddleware(async _ => {
  const {isAuth} = useAuthorizationStore();

  if (!isAuth) return;

  const {fetchSecurityInfo} = useAccountSecurityStore();

  const {status} = await fetchSecurityInfo();

  if (status.value !== 'success') {
    await clearError({redirect: '/'});
  }
});
